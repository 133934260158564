import {get,post} from "./request.js"
export const  getState = async (params) =>{
    return await get({url:"getWifiState",data:params?params.data:{}})
  }

  export const getUserInfo = (params) =>{
    return post({url:"v1.Distribution/userInfo",data:params?params.data:{}})
  }
  
  export const getQueryUserList = (params) =>{
    return post({url:"v1.Distribution/queryUserList",data:params?params.data:{}})
  }
  export const bindAlipay = (params) =>{
    return post({url:"api/v1.Distribution/bindAlipay",data:params?params.data:{}})
  }
  export const alipayList = (params) =>{
    return post({url:"api/v1.Distribution/alipayList",data:params?params.data:{}})
  }
  export const alipayEdit = (params) =>{
    return post({url:"api/v1.Distribution/alipayEdit",data:params?params.data:{}})
  }
  export const loginStatus = (params) =>{
    return post({url:"api/v1.Distribution/loginStatus",data:params?params.data:{}})
  }
  export const getLoginInfo = (params) =>{
    return post({url:"api/v1.Distribution/getLoginInfo",data:params?params.data:{}})
  }
  export const quecode = (params) =>{
    return post({url:"api/v1.Distribution/quecode",data:params?params.data:{}})
  }
  export const buyCard = (params) =>{
    return post({url:"api/v1.Distribution/buyCard",data:params?params.data:{}})
  }
  export const saveAddress = (params) =>{
    return post({url:"api/v1.Distribution/saveAddress",data:params?params.data:{}})
  }
  export const deleteAddress = (params) =>{
    return post({url:"api/v1.Distribution/deleteAddress",data:params?params.data:{}})
  }
  export const addAddress = (params) =>{
    return post({url:"api/v1.Distribution/addAddress ",data:params?params.data:{}})
  }
  export const addressList = (params) =>{
    return post({url:"api/v1.Distribution/addressList",data:params?params.data:{}})
  }
  export const downRecord = (params) =>{
    return post({url:"api/v1.Order/downRecord",data:params?params.data:{}})
  }
  export const getCouponsList = (params) =>{
    return post({url:"getCouponsList",data:params?params.data:{}})
  }
  export const getUserInfoOpenId = (params) =>{
    return post({url:"getUserInfo",data:params?params.data:{}})
  }
  export const getPostShare = (params) =>{
    return post({url:"https://jiaapi.jiusuding.cn/api/v1.Qrcode/qrcode",data:params?params.data:{}})
  }
  export default{getUserInfo,getState,getQueryUserList,bindAlipay,alipayList,alipayEdit,loginStatus,getLoginInfo,quecode,buyCard,addAddress,deleteAddress,saveAddress,addressList,downRecord,getCouponsList,getUserInfoOpenId,getPostShare} 