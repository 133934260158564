<template>
  <div class="box" ontouchstart="">
    <div class="account_title">
      <div class="left"><van-icon name="arrow-left" /></div>
      绑定支付宝账号
    </div>
    <div class="bind_befor" v-if="showBind">
      <div class="account_card">
        <div class="account_number">
          <p>支付宝账号<span>支付宝账号必须与真实姓名一致</span></p>
          <input v-model="account" type="number" placeholder="请输入支付宝账号">
          <p>姓名</p>
          <input v-model="name" type="text" placeholder="请输入真实姓名">
        </div>        
      </div>
      <div class="btn">
        <button @click="bindAlipay">确定</button>
      </div>
    </div>
    <div class="bind_after" v-else>
      <div class="bind_after_card">
        <!-- <img class="zfb_bg" src="@/assets/images/bindAccounts/zfb_bg.png" alt=""> -->
        <p class="zfb">支付宝</p>
        <div class="zfb_num">
          <p class="num">{{account}}</p>
          <button @click.capture ="editss"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var userInfos = sessionStorage.getItem('UserInfo')
var option =JSON.parse(userInfos)
console.log(option,'123');
document.body.addEventListener('touchstart', function () { });
import distubritionTwoApi from '@/untils/distubritionTwo.js'
export default {
  data(){
    return{
      tmp:'',
      showBind:true,//展示输入框或卡片
      isBindAlipay:true,//判断否是添加还是修改,true是添加,falses是修改
      account:'',//支付宝账号
      name:'',
    }
  },
  created(){
    this.alipayList()
  },
  methods:{
    //修改支付宝账号
    editss(){
      this.showBind=true
      this.isBindAlipay=false
      this.name = ''
      console.log(this.name);

    },
    // 绑定支付宝
    bindAlipay(){
      this.accountInfo()
      this.showBind = false;
      if(this.isBindAlipay){
        let data ={
        name:this.name,
        account:this.account,
        user_id:option.id
      }
      distubritionTwoApi.bindAlipay({data}).then((res)=>{
        if(res.data.code == 200){
          this.$toast(res.data.msg);
          this.showBind = false;
          this.isBindAlipay = false
        }else{
          this.$toast(res.data.msg);
        }
        console.log(res,'绑定');
      })
      }else{
        this.alipayEdit()
      }
      
    },
    // 展示支付宝列表
    alipayList(){
      let data = {
        mobile:option.mobile
      }
      distubritionTwoApi.alipayList({data}).then((res)=>{
        console.log(res);
      })
    },
    //支付宝修改
   alipayEdit(){
    console.log(this.name);
    let data ={
      mobile:option.mobile,
      alipay_id:1,
      account:this.account,
      name:this.name
    }
    distubritionTwoApi.alipayEdit({data}).then((res)=>{
      console.log(res,'修改');
      if(res.data.code ==200){
        this.isBindAlipay = true
      }
    })
   },
    accountInfo(){
        // this.account = this.account.replace(/(.{5}).*(.{5})/,"$1******$2");
        this.account = this.account.replace(this.account.substr(3, 4), '****')
        console.log(this.account,'99999');
    },
    ok(){
      this.accountInfo()

      this.showBind = false
      this.alipayList()
    }
  }
}
</script>

<style lang="less" scoped>
.box{
  min-height: 100vh;
  background-color: #fff;
  .account_title{
      width: 100%;
      height: 44px;
      line-height: 44px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      font-size: 16px;
      color: #333;
      font-weight: 550;
      .left{
        float: left;
      }
      
  }
  // 支付宝账号输入
  .account_card{
    // width: 100%;
    width: 345px;
    height: 238px;
    background-color: #fff;
    margin: 20px auto;
    border-radius: 10px;
    box-shadow: 0px 2px 10px 0px rgba(5, 54, 102, 0.1);
    .account_number{
      margin-top: 28px;
      p{
        font-weight: 700;
        font-size: 16px;
        color: #333333;
        margin-left: 24px;
        padding-top: 27px;
        line-height: 24px;

        span{
        color: #999999;
        font-size: 12px;
        margin-left: 2px;
        }
      }
      input{
        box-sizing: border-box;
        width: 305px;
        height: 40px;
        border-radius: 60px;
        background: #EFF3F6;
        margin: 11px 20px;
        padding-left: 19px;
        font-size: 14px;
      }
      input::-webkit-input-placeholder {
        color: #999999;
      }
      
    }
  }
  .btn{
    text-align: center;
    margin-top: 41px;
    button{
    width: 307px;
    height: 43px;
    border-radius: 21px;
    background: #1E6FFF;
    font-size: 18px;
    font-weight: 500;
    line-height: 13px;
    color: #fff;
    }
  }
  .bind_after{
    margin: 0 auto;
    width: 326px;
    height: 122px;
    border-radius: 10px;
    box-sizing: border-box;
    // background: linear-gradient(141deg, #1088FE 34%, #1088FE 34%, #19A2F0 103%, #19A2F0 103%);
    // background: url('~@/assets/images/bindAccounts/zfb_bg.png') no-repeat;
    background: url('~@/assets/images/bindAccounts/zfb_bg.png') no-repeat 235px 33px,linear-gradient(141deg, #1088FE 34%, #1088FE 34%, #19A2F0 103%, #19A2F0 103%);
    // background: url(https://img.alicdn.com/imgextra/i4/1881744325/O1CN01JBktT81hotb8c6Py0_!!1881744325.png) center no-repeat,linear-gradient(to bottom,red,#3c3f40);
    // background-position: 235px 33px;
    // background-size: 50%;
    position: relative;
    overflow: hidden;
    .zfb_bg{
      position: absolute;
      top: 33px;
      left: 235px;
    }
    .zfb{
      font-size: 16px;
      margin-top: 20px;
      margin-left: 22px;
      color: #fff;
    }
    .zfb_num{
      margin-top: 42px;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      color: #fff;
      .num{
        margin-left: 21px;
      }
      button{
        width: 20px;
        height: 20px;
        background: url(~@/assets/images/bindAccounts/edit.png) no-repeat;
        margin-right: 25px;
      }
    }
  }
}


</style>